



































import Vue from 'vue';

const standardToolbar = [
  'p', 'h2', 'h3', 'bulletList', 'orderedList', 'blockquote', '|', 'bold', 'italic', 'clear', '|', 'link', '>',
  '#undo', '#redo', '#clear'
];
const denseToolbar = ['bold', 'italic', 'clear', 'link'];

interface Editor {
  commands: {
    undo: () => void;
    redo: () => void;
    setContent: (content: string) => void;
    clearContent: () => void;
    focus: () => void;
  }
}

interface Props {
  value?: string;
  editable: boolean;
  activity: boolean;
  heading?: string;
  placeholder?: string;
  displayPlaceholder?: boolean;
  dense?: boolean;
}

interface Data {
  currentValue: string;
}

interface Computed {
  shouldDisplay: boolean;
  toolbarItems: string[];
  loaderType: string;
}

interface Methods {
  acceptInput(): void;
  undo(editor: Editor): void;
  redo(editor: Editor): void;
  clear(editor: Editor): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Attribute',
  props: {
    value: {
      type: String,
      required: false
    },
    editable: {
      type: Boolean,
      required: true
    },
    activity: {
      type: Boolean,
      required: true
    },
    heading: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    displayPlaceholder: {
      type: Boolean,
      required: false
    },
    dense: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      currentValue: this.value || ''
    };
  },
  computed: {
    shouldDisplay() {
      return this.activity || this.editable || !!this.currentValue || !!this.displayPlaceholder;
    },
    toolbarItems() {
      return this.dense ? denseToolbar : standardToolbar;
    },
    loaderType() {
      return this.dense ? 'text' : 'article';
    }
  },
  methods: {
    acceptInput() {
      // The WYSIWYG will sometimes generate empty HTML elements, e.g. `<p></p>`, which results in elements being
      // erroneously displayed because the value is not empty. We can strip these using a `<div>` element that is
      // never added to the document, just used to convert HTML to raw text. If there is no raw text, then we can
      // clear the value.
      const textExtractor = document.createElement('div');
      textExtractor.innerHTML = this.currentValue;
      const textValue = textExtractor.innerText.trim();
      if (!textValue) {
        this.currentValue = '';
      }
      this.$emit('input', this.currentValue);
    },
    undo(editor) {
      editor.commands.undo();
      editor.commands.focus();
    },
    redo(editor) {
      editor.commands.redo();
      editor.commands.focus();
    },
    clear(editor) {
      editor.commands.clearContent();
      editor.commands.focus();
    }
  },
  watch: {
    value() {
      this.currentValue = this.value || '';
    }
  }
});

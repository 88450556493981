









































import Vue from 'vue';
import { None } from '@/util/types';

interface Computed {
  emailStatusIcon: string;
  emailStatusIconColor: string;
  emailStatusTooltip: string;
}

export default Vue.extend<None, None, Computed>({
  name: 'UserDetails',
  computed: {
    emailStatusIcon() {
      return this.$user.attributes.emailVerified ? 'mdi-email-check-outline' : 'mdi-email-alert-outline';
    },
    emailStatusIconColor() {
      return this.$user.attributes.emailVerified ? 'success' : 'warning';
    },
    emailStatusTooltip() {
      return this.$user.attributes.emailVerified ? 'Verified' : 'Not verified';
    }
  }
});

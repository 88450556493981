import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';
import qs from 'qs';
import { initGtagAnalytics } from '@/plugins/gtag';
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import Terms from '@/views/Terms.vue';
import Login from '@/views/Login.vue';
import Signup from '@/views/Signup.vue';
import SignupConfirmation from '@/views/SignupConfirmation.vue';
import RecoverPassword from '@/views/RecoverPassword.vue';
import RecoverPasswordConfirmation from '@/views/RecoverPasswordConfirmation.vue';
import Profile from '@/views/Profile.vue';
import SearchResults from '@/views/Search.vue';
import Entity from '@/views/Entity.vue';
import NotFound from '@/views/NotFound.vue';

const routes: Array<RouteConfig> = [
  // Home page and other static pages.
  {
    path: '/',
    component: Home
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/terms',
    component: Terms
  },

  // User authentication and account pages.
  {
    path: '/user/login',
    component: Login
  },
  {
    path: '/user/signup',
    component: Signup
  },
  {
    path: '/user/signup/confirm',
    component: SignupConfirmation
  },
  {
    path: '/user/recover',
    component: RecoverPassword
  },
  {
    path: '/user/recover/confirm',
    component: RecoverPasswordConfirmation
  },
  {
    path: '/user/profile',
    component: Profile
  },

  // List entities by type: redirect to a pre-canned search
  {
    path: '/:type(video|audio|photographs|documents|people|ensembles|organisations|locations|events)',
    redirect: (to) => ({
      path: '/search',
      query: {
        type: [to.params.type]
      }
    })
  },

  // Search results
  {
    path: '/search',
    component: SearchResults
  },

  // Entity details page
  {
    path: '/:type(video|audio|photographs|documents|people|ensembles|organisations|locations|events)/:slug',
    component: Entity
  },

  // Catch-all fake 404
  {
    path: '*',
    component: NotFound
  }
];

export function buildRouter(): VueRouter {
  Vue.use(VueRouter);
  Vue.use(VueMeta);

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const result = qs.stringify(query);
      return result ? `?${result}` : '';
    }
  });

  router.afterEach((to, from) => {
    if (to.path !== from.path) {
      window.scrollTo({ top: 0, left: 0 });
    }
  });

  initGtagAnalytics(router);

  return router;
}

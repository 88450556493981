























import Vue from 'vue';

export default Vue.extend({
  name: 'WhatIsWanma',
  computed: {
    aboutButtonLabel() {
      return this.$vuetify.breakpoint.mdAndUp
        ? 'About the Western Australian New Music Archive'
        : 'About WANMA';
    },
    searchButtonLabel() {
      return this.$vuetify.breakpoint.mdAndUp
        ? 'Search the WANMA Collection'
        : 'Search the Collection';
    }
  }
});

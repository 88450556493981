








































































































































































































import Vue from 'vue';
import { Hit, UiState } from 'instantsearch.js';
import { Label, MoveTarget, Path, TypePath } from '@/api-schema';
import { getEntityTypes } from '@/util/entityTypes';
import { getPath, getSlug } from '@/util/urls';
import { getSearchClient, getSearchIndexName } from '@/services/algolia';

interface Data {
  displayConfirmDeleteDialog: boolean;
  displayMoveDialog: boolean;
  targetType?: TypePath;
  targetSlug?: Path;
  targetLabel?: Label;
  targetSlugLocked: boolean;
  displayMergeDialog: boolean;
  searchClient: unknown;
  indexName: string;
  mergeTarget: Hit | undefined;
  mergeTargetSearch: string;
}

interface Methods {
  confirmMove: () => Promise<void>;
  confirmMerge: () => Promise<void>;
}

interface Computed {
  shouldDisplay: boolean;
  path: Path;
  tooltip: string;
  icon: string;
  publishTooltip: string;
  publishIcon: string;
  availableEntityTypePathsForMove: TypePath[];
  resultingUrl: Path;
  labelToSave: string;
  isEmptyMove: boolean;
  mergeSearchUiState: UiState;
}

interface Props {
  value: boolean;
  type: TypePath;
  slug: Path;
  label: Path;
  activity: boolean;
  modified: boolean;
  published: boolean;
  saveChanges: () => Promise<void>;
  revertChanges: () => void;
  moveEntity: (target: MoveTarget) => Promise<void>;
  mergeEntities: (target: Path) => Promise<void>;
  deleteEntity: () => Promise<void>;
  togglePublished: () => Promise<void>;
}

const mediaTypes: TypePath[] = ['video', 'audio', 'documents', 'photographs'];

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Controls',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    type: {
      type: String as () => TypePath,
      required: true
    },
    slug: {
      type: String as () => Path,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    activity: {
      type: Boolean,
      required: true
    },
    modified: {
      type: Boolean,
      required: true
    },
    published: {
      type: Boolean,
      required: true
    },
    saveChanges: {
      type: Function as unknown as () => () => Promise<void>,
      required: true
    },
    revertChanges: {
      type: Function as unknown as () => () => void,
      required: true
    },
    moveEntity: {
      type: Function as unknown as () => (target: MoveTarget) => Promise<void>,
      required: true
    },
    mergeEntities: {
      type: Function as unknown as () => (target: Path) => Promise<void>,
      required: true
    },
    deleteEntity: {
      type: Function as unknown as () => () => Promise<void>,
      required: true
    },
    togglePublished: {
      type: Function as unknown as () => () => Promise<void>,
      required: true
    }
  },
  data() {
    return {
      displayConfirmDeleteDialog: false,
      displayMoveDialog: false,
      targetType: undefined as TypePath | undefined,
      targetSlug: undefined as Path | undefined,
      targetLabel: undefined as Label | undefined,
      targetSlugLocked: false,
      displayMergeDialog: false,
      searchClient: getSearchClient(),
      indexName: getSearchIndexName(),
      mergeTarget: undefined as Hit | undefined,
      mergeTargetSearch: ''
    };
  },
  computed: {
    shouldDisplay() {
      return !this.activity && !!this.$user.cognitoUser;
    },
    path() {
      return getPath(this.type, this.slug);
    },
    tooltip() {
      return this.value ? 'Editing - click for preview' : 'Viewing - click to edit';
    },
    icon() {
      return this.value ? 'mdi-eye' : 'mdi-pencil';
    },
    publishTooltip() {
      return this.published ? 'Published - click to revert to draft' : 'Draft - click to publish';
    },
    publishIcon() {
      return this.published ? 'mdi-publish-off' : 'mdi-publish';
    },
    availableEntityTypePathsForMove() {
      if (mediaTypes.includes(this.type)) {
        return [this.type];
      }
      return getEntityTypes()
        .map(({ path }) => path.substring(1) as TypePath)
        .filter((type) => !mediaTypes.includes(type));
    },
    resultingUrl() {
      return `/${this.targetType}/${this.targetSlug}`;
    },
    labelToSave() {
      return this.targetLabel?.trim() || '';
    },
    isEmptyMove() {
      return this.type === this.targetType && this.slug === this.targetSlug && this.label === this.labelToSave;
    },
    mergeSearchUiState(): UiState {
      return {
        [this.indexName]: {
          refinementList: {
            type: [this.type]
          },
          hitsPerPage: 5
        }
      };
    }
  },
  methods: {
    async confirmMove() {
      await this.moveEntity({
        type: this.targetType as TypePath,
        slug: this.targetSlug as Path,
        label: this.labelToSave
      });
    },
    async confirmMerge() {
      if (!this.mergeTarget) {
        return;
      }
      await this.mergeEntities(this.mergeTarget.path);
    }
  },
  watch: {
    displayMoveDialog(value) {
      if (!value) {
        return;
      }
      this.targetType = this.type;
      this.targetSlug = this.slug;
      this.targetLabel = this.label;
      this.targetSlugLocked = false;
    },
    targetLabel(value) {
      if (!this.targetSlugLocked) {
        this.targetSlug = this.labelToSave === this.label ? this.slug : getSlug(value);
      }
    }
  }
});

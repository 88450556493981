import { TypePath } from '@/api-schema';

/* eslint-disable-next-line no-misleading-character-class */
const combine = /[\u0300-\u036F\u1AB0-\u1AFF\u1DC0-\u1DFF]+/g;
const split = /[A-Za-z\d]+/g;

export const getSlug = (text: string): string => {
  const fragments = text
    .trim()
    .toLowerCase()
    .normalize('NFKD')
    .replace(combine, '')
    .match(split);
  return fragments ? fragments.join('-') : '';
};

export const getPath = (type: TypePath, slug: string): string => `/${type}/${slug}`;

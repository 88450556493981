






import Vue from 'vue';
import { formatDate } from '@/util/formatting';
import { Modification } from '@/api-schema';
import { None } from '@/util/types';

interface Props {
  value?: Modification;
}

interface Computed {
  at: string | undefined,
  by: string | undefined
}

export default Vue.extend<None, None, Computed, Props>({
  name: 'LastModified',
  props: {
    value: {
      type: Object as () => Modification,
      required: false
    }
  },
  computed: {
    at() {
      return formatDate(this.value?.at);
    },
    by() {
      return this.value?.by;
    }
  }
});


































import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import { None } from '@/util/types';

const stopWords = ['the', 'a', 'of', 'at', 'for', 'in', 'on', 'his', 'her', 'their', 'him', 'he', 'she', 'they'];

interface SearchTerm {
  term: string;
  url: string;
}

interface Computed {
  inherentSearch: string[];
  inherentSearchUrl: string;
  inherentSearchTerms: SearchTerm[];
}

export default Vue.extend<None, None, Computed>({
  name: 'NotFound',
  computed: {
    inherentSearch() {
      return decodeURIComponent(window.location.pathname)
        .substring(1)
        .split(/[/-]/)
        .filter((word) => stopWords.indexOf(word) < 0);
    },
    inherentSearchUrl() {
      return `/search?q=${this.inherentSearch.join('+')}`;
    },
    inherentSearchTerms() {
      return this.inherentSearch.map((term) => ({ term, url: `/search?q=${term}` }));
    }
  },
  metaInfo(): MetaInfo {
    return {
      title: 'Not Found',
      meta: [
        {
          name: 'og:title',
          content: 'Not Found'
        },
        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:image',
          content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
        },
        {
          name: 'og:url',
          content: window.location.href
        },
        {
          name: 'og:author',
          content: 'WANMA'
        },
        {
          name: 'og:description',
          content: 'Sorry, but the page you are looking for does not exist.'
        }
      ]
    };
  }
});

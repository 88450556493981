












import Vue from 'vue';
import { None } from '@/util/types';

interface Data {
  displaySnackbar: boolean;
  message: string;
  color?: string;
  activity: boolean;
}

export default Vue.extend<Data, None, None>({
  name: 'AuthNotifications',
  data() {
    return {
      displaySnackbar: false,
      message: '',
      color: undefined as string | undefined,
      activity: false
    };
  },
  authEvent(type, message) {
    if (type === 'loading') {
      this.activity = true;
    } else {
      this.activity = false;
      this.color = type === 'error' ? 'error' : 'success';
      this.message = message || '';
      this.displaySnackbar = !!message;
    }
  }
});

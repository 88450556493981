











import Vue from 'vue';
import { None } from '@/util/types';
import { Entity } from '@/api-schema';
import TagChip from '@/components/TagChip.vue';

interface Props {
  item: Entity;
}

export default Vue.extend<None, None, None, Props>({
  name: 'Tags',
  components: { TagChip },
  props: {
    item: {
      type: Object as () => Entity,
      required: true
    }
  }
});

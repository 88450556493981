import { ApolloProvider } from 'vue-apollo';
import { gql } from '@apollo/client/core';
import { EditEntityInput, Entity } from '@/api-schema';
import { commonFields, mediaFields } from '@/services/api/fields';

const editArticleEntityMutation = gql`
  mutation EditArticleEntity($input: EditEntityInput!) {
    entity: editEntity(input: $input) {
      ${commonFields}
      ${mediaFields}
    }
  }
`;

export const editMediaEntity = async (
  apolloProvider: ApolloProvider,
  input: EditEntityInput
): Promise<Entity> => {
  const result = await apolloProvider.defaultClient.mutate({
    mutation: editArticleEntityMutation,
    variables: { input }
  });
  return result.data.entity;
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-divider'),_c('v-footer',{attrs:{"color":"grey lighten-2"}},[_c('v-container',{staticClass:"text-caption"},[_c('v-row',{attrs:{"dense":""}},[_c('v-spacer'),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"4","md":"3","lg":"2"}},[_vm._v(" WA New Music media "),_c('v-list',{attrs:{"dense":"","nav":"","color":"grey lighten-2"}},[_vm._l((_vm.entityTypes.slice(0, 4)),function(ref){
var path = ref.path;
var label = ref.label;
var icon = ref.icon;
return [_c('v-list-item',{key:path,attrs:{"to":path}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-light"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(icon))]),_vm._v(" "+_vm._s(label)+" ")],1)],1)],1)]})],2)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"4","md":"3","lg":"2"}},[_vm._v(" WA New Music articles "),_c('v-list',{attrs:{"dense":"","nav":"","color":"grey lighten-2"}},[_vm._l((_vm.entityTypes.slice(4)),function(ref){
var path = ref.path;
var label = ref.label;
var icon = ref.icon;
return [_c('v-list-item',{key:path,attrs:{"to":path}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-light"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(icon))]),_vm._v(" "+_vm._s(label)+" ")],1)],1)],1)]})],2)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"4","md":"3","lg":"2"}},[_vm._v(" More Information "),_c('v-list',{attrs:{"dense":"","nav":"","color":"grey lighten-2"}},[_c('v-list-item',{attrs:{"to":"/"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-light"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-home-outline")]),_vm._v(" Home ")],1)],1)],1),_c('v-list-item',{attrs:{"to":"/about"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-light"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-information-outline")]),_vm._v(" About ")],1)],1)],1),_c('v-list-item',{attrs:{"to":"/terms"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-light"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-scale-balance")]),_vm._v(" Terms ")],1)],1)],1),_c('v-list-item',{attrs:{"to":"/search"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-light"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-magnify")]),_vm._v(" Search ")],1)],1)],1)],1)],1),_c('v-spacer')],1)],1)],1),_c('v-divider'),_c('v-sheet',{staticClass:"text-body-2 white--text grey darken-4 text-no-wrap text-center py-3"},[_vm._v(" Copyright © 2013 - "+_vm._s(new Date().getFullYear())),_c('br'),_c('router-link',{staticClass:"white--text text-decoration-none",attrs:{"to":"/"},domProps:{"textContent":_vm._s(_vm.wanma)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
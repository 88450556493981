var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.indexName,"routing":_vm.routing}},[_c('v-container',{staticClass:"my-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h3 text-lg-h2 font-weight-thin"},[_vm._v(" Search ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
return [_c('SearchBox',{attrs:{"value":currentRefinement}})]}}])}),_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var nbHits = ref.nbHits;
return [_c('v-sheet',{staticClass:"text-right blue-grey--text text-caption"},[_c('strong',[_vm._v(_vm._s(_vm.formatNumber(nbHits)))]),_vm._v(" results found ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 py-lg-3",attrs:{"cols":"12","lg":"3","xl":"2"}},[_c('v-expansion-panels',{attrs:{"popout":"","flat":"","hover":"","mandatory":_vm.$vuetify.breakpoint.lgAndUp}},[_c('v-expansion-panel',[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-expansion-panel-header',[_vm._v(" Options ")]):_vm._e(),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('ViewTypeToggle',{model:{value:(_vm.viewType),callback:function ($$v) {_vm.viewType=$$v},expression:"viewType"}}),_c('ais-refinement-list',{attrs:{"attribute":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refine = ref.refine;
return [_c('RefinementList',{attrs:{"title":"Type","items":items,"refine":refine}})]}}])}),_c('ais-refinement-list',{attrs:{"attribute":"tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refine = ref.refine;
var searchForItems = ref.searchForItems;
return [_c('RefinementList',{attrs:{"title":"Tags","items":items,"refine":refine,"search-for-items":searchForItems}})]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"9","xl":"10"}},[_c('ais-infinite-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var isLastPage = ref.isLastPage;
var refineNext = ref.refineNext;
return [_c('SearchResults',{attrs:{"items":items,"viewType":_vm.viewType}}),_c('v-btn',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (isVisible) { return isVisible && !isLastPage && refineNext(); }),expression:"(isVisible) => isVisible && !isLastPage && refineNext()"}],attrs:{"type":"button","block":"","outlined":"","prepend-icon":"mdi-arrow-expand-down","disabled":isLastPage},domProps:{"textContent":_vm._s(isLastPage ? 'No more results' : 'Load next results')},on:{"click":function($event){$event.preventDefault();return refineNext.apply(null, arguments)}}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }




























import Vue from 'vue';
import { acknowledgeCountry, hasAcknowledgedCountry } from '@/localStorage/acknowledgementOfCountry';

interface Data {
  showBanner: boolean;
}

interface Methods {
  accept(): void;
}

interface Computed {
  buttonText: string;
}

export default Vue.extend<Data, Methods, Computed>({
  name: 'AcknowledgementOfCountry',
  data() {
    return {
      showBanner: !hasAcknowledgedCountry()
    };
  },
  methods: {
    accept() {
      acknowledgeCountry();
      this.showBanner = false;
    }
  },
  computed: {
    buttonText() {
      return this.$vuetify.breakpoint.mdAndUp
        ? 'I acknowledge and respect our Country\'s traditional owners'
        : 'Acknowledge';
    }
  }
});

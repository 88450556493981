



































import Vue from 'vue';
import PassiveMessage from '@/components/PassiveMessage.vue';
import { DataTableHeader } from '@/util/types';
import { formatDate, formatSeconds } from '@/util/formatting';
import { listImportRequests } from '@/services/api/listImportRequests';
import { ImportRequest } from '@/api-schema';

interface Data {
  importRequests: ImportRequest[];
  activity: boolean;
  errorMessage: string | undefined;
}

interface Methods {
  formatDate: typeof formatDate;
  formatSeconds: typeof formatSeconds;
  getBNumber(name: string): string;
  loadData(): Promise<void>;
}

interface Computed {
  headers: DataTableHeader[];
}

export default Vue.extend<Data, Methods, Computed>({
  name: 'ImportRequests',
  data() {
    return {
      importRequests: [] as ImportRequest[],
      activity: false,
      errorMessage: undefined as string | undefined
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Source',
          value: 'source',
          sortable: false
        },
        {
          text: 'Item Imported',
          value: 'name',
          sortable: false
        },
        {
          text: 'Started',
          value: 'startDate',
          sortable: false
        },
        {
          text: 'Stopped',
          value: 'stopDate',
          sortable: false
        },
        {
          text: 'Duration',
          value: 'duration',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        }
      ];
    }
  },
  methods: {
    formatDate,
    formatSeconds,
    getBNumber(name: string) {
      return name.replace(/^\w+-import-(\w*)-\d+-\w+$/, '$1') || '-';
    },
    async loadData() {
      this.activity = true;
      this.errorMessage = undefined;
      try {
        this.importRequests = await listImportRequests(this.$apolloProvider);
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.activity = false;
      }
    }
  },
  async mounted() {
    await this.loadData();
  },
  components: { PassiveMessage }
});

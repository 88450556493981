import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { ApolloClient, concat, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { getValidSession } from '@/auth/cognito';

const getTokenHeaders = (accessToken: CognitoAccessToken) => {
  const token = accessToken.getJwtToken()?.toString();
  return {
    headers: {
      authorization: `Bearer ${token}`
    }
  };
};

// Client that uses Cognito user pool JWT authentication. This is used for all (read+write) operations when signed in.
export const createAuthenticatedClient = (uri: string): ApolloClient<NormalizedCacheObject> => {
  const httpLink = new HttpLink({ uri });
  const authLink = setContext(() => new Promise<Record<string, unknown> | void>((resolve, reject) => {
    getValidSession()
      .then((session) => {
        const accessToken = session?.getAccessToken();
        resolve(accessToken ? getTokenHeaders(accessToken) : undefined);
      })
      .catch((err) => {
        reject(err);
      });
  }));
  return new ApolloClient({
    name: 'authenticated',
    link: concat(authLink, httpLink),
    cache: new InMemoryCache()
  });
};



























import Vue from 'vue';
import { None } from '@/util/types';
import { Entity } from '@/api-schema';
import { UserDetails } from '@/auth/types';

// TODO Probably belongs in schema.ts
interface PendingRevision {
  entity: Pick<Entity, 'path' | 'label'>;
  editor: UserDetails;
  timestamp: number;
}

interface Data {
  pendingRevisions: PendingRevision[];
}

export default Vue.extend<Data, None, None>({
  name: 'PendingRevisions',
  data() {
    return {
      pendingRevisions: [
        // {
        //   entity: {
        //     path: '/people/hope-cat',
        //     label: 'Hope, Cat'
        //   },
        //   editor: {
        //     attributes: {
        //       name: 'John Smith',
        //       email: 'john.smith@mailinator.com'
        //     }
        //   },
        //   timestamp: Date.now() - 3600
        // },
        // {
        //   entity: {
        //     path: '/people/pye-david',
        //     label: 'Pye, David'
        //   },
        //   editor: {
        //     attributes: {
        //       name: 'John Smith',
        //       email: 'john.smith@mailinator.com'
        //     }
        //   },
        //   timestamp: Date.now() - 3600
        // }
      ]
    };
  }
});

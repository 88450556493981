import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { Entity, MoveEntityInput } from '@/api-schema';

const moveEntityMutation = gql`
  mutation MoveEntity($input: MoveEntityInput!) {
    moved: moveEntity(input: $input) {
      path
      label
      redirect
    }
  }
`;

export const moveEntity = async (
  apolloProvider: ApolloProvider,
  input: MoveEntityInput
): Promise<Pick<Entity, 'path' | 'label' | 'redirect'>> => {
  const result = await apolloProvider.defaultClient.mutate({
    mutation: moveEntityMutation,
    variables: { input }
  });
  return result.data.moved;
};
































import Vue from 'vue';
import { Entity } from '@/api-schema';
import { DataTableHeader } from '@/util/types';
import { listDraftEntities } from '@/services/api/listDraftEntities';
import { formatDate } from '@/util/formatting';
import PassiveMessage from '@/components/PassiveMessage.vue';
import { getEntityType } from '@/util/entityTypes';

type DraftEntityListing = Pick<Entity, 'path' | 'type' | 'label' | 'lastModified'>;

interface Data {
  draftEntities: DraftEntityListing[];
  activity: boolean;
  errorMessage: string | undefined;
}

interface Methods {
  formatDate: typeof formatDate;
  getEntityType: typeof getEntityType;
}

interface Computed {
  headers: DataTableHeader[];
}

export default Vue.extend<Data, Methods, Computed>({
  name: 'DraftEntities',
  data() {
    return {
      draftEntities: [],
      activity: true,
      errorMessage: undefined
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Entity',
          value: 'label',
          sortable: true
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true
        },
        {
          text: 'Last Modified By',
          value: 'lastModified.by',
          sortable: true
        },
        {
          text: 'Last Modified At',
          value: 'lastModified.at',
          sortable: true
        }
      ];
    }
  },
  async beforeMount() {
    this.activity = true;
    try {
      this.draftEntities = await listDraftEntities(this.$apolloProvider);
    } catch (e) {
      this.errorMessage = e.message;
    } finally {
      this.activity = false;
    }
  },
  methods: {
    formatDate,
    getEntityType
  },
  components: {
    PassiveMessage
  }
});




















import Vue from 'vue';
import { EntityType, getEntityTypes } from '@/util/entityTypes';
import { None } from '@/util/types';

interface Computed {
  entityTypes: EntityType[];
}

export default Vue.extend<None, None, Computed>({
  name: 'EntityTypesList',
  computed: {
    entityTypes() {
      return getEntityTypes();
    }
  }
});

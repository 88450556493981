




































































































































































































































































































import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import { Entity, EntityUpdates, Link, MediaTypePath, MoveTarget, Path } from '@/api-schema';
import { retrieveMediaEntity } from '@/services/api/retrieveMediaEntity';
import { retrieveArticleEntity } from '@/services/api/retrieveArticleEntity';
import { editMediaEntity } from '@/services/api/editMediaEntity';
import { editArticleEntity } from '@/services/api/editArticleEntity';
import { retrieveEditableMode, storeEditableMode } from '@/localStorage/editable';
import { deepCopy, deepEquals } from '@/util/deep';
import NotFound from '@/views/NotFound.vue';
import RedirectGuard from '@/components/RedirectGuard.vue';
import PassiveMessage from '@/components/PassiveMessage.vue';
import Controls from '@/components/entity/Controls.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import Heading from '@/components/entity/Heading.vue';
import Tags from '@/components/entity/Tags.vue';
import Attribute from '@/components/entity/Attribute.vue';
import AttributeCard from '@/components/entity/AttributeCard.vue';
import Avatar from '@/components/entity/Avatar.vue';
import MediaViewer from '@/components/entity/MediaViewer.vue';
import WebAndSocialsCard from '@/components/entity/WebAndSocialsCard.vue';
import RelatedEntitiesCard from '@/components/entity/RelatedEntitiesCard.vue';
import LastModified from '@/components/entity/LastModified.vue';
import Provenance from '@/components/entity/Provenance.vue';
import { deleteEntity } from '@/services/api/deleteEntity';
import { moveEntity } from '@/services/api/moveEntity';
import { mergeEntities } from '@/services/api/mergeEntities';

interface Data {
  path: string;
  entity: Entity;
  originalEntity: Entity;
  editable: boolean;
  modifiedFields: (keyof Entity)[];
  showMoveDialog: boolean;
  activity: boolean;
  errorMessage?: string;
}

interface Methods {
  retrieveEntity(): Promise<void>;
  doSave(updates: EntityUpdates): Promise<void>;
  togglePublished(): Promise<void>;
  saveChanges(): Promise<void>;
  revertChanges(): void;
  moveEntity(target: MoveTarget): Promise<void>;
  mergeEntities(target: Path): Promise<void>;
  deleteEntity(): Promise<void>;
  checkChanges(): void;
  watchEntity(): void;
  confirmUnsavedChanges(): Promise<boolean>;
  beforeUnload(evt: Event): Promise<string | undefined>;
}

interface Computed {
  isMediaEntity: boolean;
  isMediaEntityWithEditableAvatar: boolean;
  mediaEntityType: MediaTypePath;
  entityKeys: (keyof Entity)[];
}

export default Vue.extend<Data, Methods, Computed>({
  name: 'Entity',
  data() {
    return {
      path: this.$route.path,
      entity: {} as unknown as Entity,
      originalEntity: {} as unknown as Entity,
      editable: retrieveEditableMode(),
      modifiedFields: [] as (keyof Entity)[],
      showMoveDialog: false,
      activity: false,
      errorMessage: undefined as undefined | string
    };
  },
  methods: {
    async retrieveEntity() {
      this.activity = true;
      this.entity = {} as unknown as Entity;
      this.originalEntity = {} as unknown as Entity;
      try {
        this.entity = this.isMediaEntity
          ? await retrieveMediaEntity(this.$apolloProvider, { path: this.path }, this.$user.cognitoUser)
          : await retrieveArticleEntity(this.$apolloProvider, { path: this.path }, this.$user.cognitoUser);
        this.originalEntity = deepCopy<Entity>(this.entity);
        this.watchEntity();
        this.checkChanges();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.activity = false;
      }
    },
    async doSave(updates: EntityUpdates) {
      this.entity = this.isMediaEntity
        ? await editMediaEntity(this.$apolloProvider, { path: this.path, updates })
        : await editArticleEntity(this.$apolloProvider, { path: this.path, updates });
      this.originalEntity = deepCopy<Entity>(this.entity);
      this.watchEntity();
      this.checkChanges();
    },
    async togglePublished() {
      if (!this.entity) {
        return;
      }
      this.activity = true;
      this.errorMessage = undefined;
      try {
        await this.doSave({ published: !this.entity.published });
      } catch (e) {
        this.errorMessage = e.message ?? `${e}`;
      } finally {
        this.activity = false;
      }
    },
    async saveChanges() {
      if (!this.entity || this.modifiedFields.length === 0) {
        return;
      }
      this.activity = true;
      this.errorMessage = undefined;
      try {
        const updates = this.modifiedFields.reduce<EntityUpdates>(
          (result, field) => {
            switch (field) {
              case 'related':
                return {
                  ...result,
                  [field]: this.entity.related?.map<Path>(({ path }) => path)
                };
              case 'links':
                return {
                  ...result,
                  [field]: this.entity.links?.map<Link>(({ type, url }) => ({ type, url }))
                };
              default:
                return {
                  ...result,
                  [field]: this.entity[field]
                };
            }
          },
          {} as Partial<EntityUpdates>
        );
        await this.doSave(updates);
      } catch (e) {
        this.errorMessage = e.message ?? `${e}`;
      } finally {
        this.activity = false;
      }
    },
    revertChanges() {
      this.entity = deepCopy<Entity>(this.originalEntity);
      this.watchEntity();
      this.checkChanges();
    },
    async moveEntity(target: MoveTarget) {
      if (!this.entity) {
        return;
      }
      this.activity = true;
      this.errorMessage = undefined;
      try {
        const result = await moveEntity(this.$apolloProvider, {
          path: this.path,
          target
        });
        if (result.redirect) {
          await this.$router.push(result.redirect);
        }
      } catch (e) {
        this.errorMessage = e.message ?? `${e}`;
      } finally {
        this.activity = false;
      }
    },
    async mergeEntities(target: Path) {
      if (!this.entity) {
        return;
      }
      this.activity = true;
      this.errorMessage = undefined;
      try {
        const result = await mergeEntities(this.$apolloProvider, {
          path: this.path,
          target
        });
        if (result.redirect) {
          await this.$router.push(result.redirect);
        }
      } catch (e) {
        this.errorMessage = e.message ?? `${e}`;
      } finally {
        this.activity = false;
      }
    },
    async deleteEntity() {
      if (!this.entity) {
        return;
      }
      this.activity = true;
      this.errorMessage = undefined;
      try {
        await deleteEntity(this.$apolloProvider, { path: this.path });
        await this.$router.push('/');
      } catch (e) {
        this.errorMessage = e.message ?? `${e}`;
      } finally {
        this.activity = false;
      }
    },
    checkChanges() {
      this.modifiedFields = this.entityKeys.filter((key) => !deepEquals(this.entity[key], this.originalEntity[key]));
    },
    watchEntity() {
      this.entityKeys.forEach((field) => this.$watch(() => this.entity[field], () => this.checkChanges()));
    },
    // These two methods serve similar but different purposes. The confirmUnsavedChanges method is for navigation
    // *within* the application (i.e. via the router / push state). The beforeUnload method exists to protect against
    // the user *closing or reloading* the browser window (which is outside the scope of the Vue application).
    // (https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event).
    async confirmUnsavedChanges() {
      const dialog = this.$refs.confirmationDialog as unknown as { getUserResponse: () => Promise<boolean> };
      return this.modifiedFields.length === 0 || !dialog || dialog.getUserResponse();
    },
    async beforeUnload(evt: BeforeUnloadEvent) {
      if (this.modifiedFields.length > 0) {
        evt.preventDefault();
        /* eslint-disable-next-line no-param-reassign */
        evt.returnValue = 'You have unsaved changes. Are you sure you want to exit?';
      }
      return evt.returnValue;
    }
  },
  computed: {
    isMediaEntity() {
      return ['video', 'audio', 'photographs', 'documents'].includes(this.$route.params.type);
    },
    isMediaEntityWithEditableAvatar() {
      return ['video', 'audio'].includes(this.$route.params.type);
    },
    mediaEntityType() {
      return this.$route.params.type as MediaTypePath;
    },
    entityKeys() {
      return ((this.entity ? Object.keys(this.entity) : []) as (keyof Entity)[]);
    }
  },
  watch: {
    editable(value) {
      storeEditableMode(value);
    }
  },
  authEvent() {
    this.editable = retrieveEditableMode() && !!this.$user.cognitoUser;
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.beforeUnload, { capture: true });
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnload, { capture: true });
  },
  async mounted() {
    await this.retrieveEntity();
  },
  async beforeRouteUpdate(to, from, next) {
    if (await this.confirmUnsavedChanges()) {
      this.revertChanges();
      this.path = to.path;
      // Calling next() before retrieveEntity() means that the layout can be based on the new entity's type.
      next();
      await this.retrieveEntity();
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (await this.confirmUnsavedChanges()) {
      this.revertChanges();
      next();
    }
  },
  components: {
    NotFound,
    RedirectGuard,
    PassiveMessage,
    Controls,
    ConfirmationDialog,
    Heading,
    Tags,
    Attribute,
    AttributeCard,
    Avatar,
    MediaViewer,
    WebAndSocialsCard,
    RelatedEntitiesCard,
    Provenance,
    LastModified
  },
  metaInfo(): MetaInfo {
    return {
      title: this.entity?.label ?? '',
      meta: [
        {
          name: 'og:title',
          content: this.entity?.label ?? ''
        },
        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:image',
          content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
        },
        {
          name: 'og:url',
          content: window.location.href
        },
        {
          name: 'og:author',
          content: 'WANMA'
        },
        {
          name: 'og:description',
          content: `An article about ${this.entity?.label}.`
        }
      ]
    };
  }
});





























import Vue from 'vue';
import { listUsers } from '@/services/api/listUsers';
import { User } from '@/api-schema';
import PassiveMessage from '@/components/PassiveMessage.vue';
import { formatDate } from '@/util/formatting';
import { DataTableHeader } from '@/util/types';

interface Data {
  users: User[];
  activity: boolean;
  errorMessage: string | undefined;
}

interface Methods {
  formatDate: typeof formatDate;
  loadData(): Promise<void>;
}

interface Computed {
  headers: DataTableHeader[];
}

export default Vue.extend<Data, Methods, Computed>({
  name: 'UserManagement',
  data() {
    return {
      users: [] as User[],
      activity: true,
      errorMessage: undefined as string | undefined
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
          sortable: true
        },
        {
          text: 'Email Address',
          value: 'email',
          sortable: true
        },
        {
          text: 'Member Since',
          value: 'created',
          sortable: true
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true
        },
        {
          text: 'User ID',
          value: 'userId',
          sortable: false
        }
      ];
    }
  },
  methods: {
    formatDate,
    async loadData() {
      this.activity = true;
      this.errorMessage = undefined;
      try {
        this.users = await listUsers(this.$apolloProvider);
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.activity = false;
      }
    }
  },
  async mounted() {
    await this.loadData();
  },
  components: { PassiveMessage }
});






























































import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import UserDetails from '@/components/profile/UserDetails.vue';
import SlwaImport from '@/components/admin/SlwaImport.vue';
import ImportRequests from '@/components/admin/ImportRequests.vue';
import DraftEntities from '@/components/admin/DraftEntities.vue';
import PendingRevisions from '@/components/admin/PendingRevisions.vue';
import UserManagement from '@/components/admin/UserManagement.vue';

interface Data {
  tab?: number;
}

interface Methods {
  redirectIfLoggedOut(): void;
}

interface Computed {
  isCurator: boolean;
  isAdministrator: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
  name: 'Profile',
  data() {
    return {
      tab: undefined as number | undefined
    };
  },
  methods: {
    redirectIfLoggedOut() {
      if (!this.$user.cognitoUser) {
        this.$router.push('/');
      }
    }
  },
  computed: {
    isCurator() {
      return this.$user.groups?.indexOf('curators') >= 0;
    },
    isAdministrator() {
      return this.$user.groups?.indexOf('administrators') >= 0;
    }
  },
  authEvent() {
    this.redirectIfLoggedOut();
  },
  components: {
    DraftEntities,
    ImportRequests,
    UserManagement,
    SlwaImport,
    PendingRevisions,
    UserDetails
  },
  metaInfo(): MetaInfo {
    return {
      title: 'User Profile',
      meta: [
        {
          name: 'og:title',
          content: 'User Profile'
        },
        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:image',
          content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
        },
        {
          name: 'og:url',
          content: window.location.href
        },
        {
          name: 'og:author',
          content: 'WANMA'
        },
        {
          name: 'og:description',
          content: 'User profile page.'
        }
      ]
    };
  }
});

import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { Entity, MergeEntitiesInput } from '@/api-schema';

const mergeEntitiesMutation = gql`
  mutation MergeEntities($input: MergeEntitiesInput!) {
    merged: mergeEntities(input: $input) {
      path
      label
      redirect
    }
  }
`;

export const mergeEntities = async (
  apolloProvider: ApolloProvider,
  input: MergeEntitiesInput
): Promise<Pick<Entity, 'path' | 'label' | 'redirect'>> => {
  const result = await apolloProvider.defaultClient.mutate({
    mutation: mergeEntitiesMutation,
    variables: { input }
  });
  return result.data.merged;
};

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { UserDetails } from '@/auth/types';
import { notify, notifyError } from '@/auth/callbacks';

const userPool: CognitoUserPool = new CognitoUserPool({
  UserPoolId: process.env.VUE_APP_USER_POOL_ID as string,
  ClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID as string
});

const currentUser: UserDetails = {
  cognitoUser: null,
  attributes: {},
  groups: []
};

export const getCurrentUser = (): UserDetails => currentUser;

export const getValidSession = async (): Promise<CognitoUserSession | undefined> => {
  const user = currentUser.cognitoUser;
  if (!user) {
    return undefined;
  }
  const session = user.getSignInUserSession();
  if (!session) {
    return undefined;
  }
  if (!session.isValid()) {
    return new Promise<CognitoUserSession | undefined>((resolve, reject) => {
      user.refreshSession(session.getRefreshToken(), (err, refreshedSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(refreshedSession);
        }
      });
    });
  }
  return session;
};

export const initializeAuth = async (): Promise<void> => {
  currentUser.cognitoUser = userPool.getCurrentUser();

  try {
    await new Promise<void>((resolve, reject) => {
      if (currentUser.cognitoUser) {
        currentUser.cognitoUser.getSession((error: Error | null) => (error ? reject(error) : resolve()));
      } else {
        resolve();
      }
    });
    const session = await getValidSession();
    if (session) {
      const { payload } = session.getIdToken();
      currentUser.attributes = {
        userId: payload.sub,
        name: payload.name,
        email: payload.email,
        emailVerified: payload.email_verified
      };
      currentUser.groups = session.getIdToken().payload['cognito:groups'] || [];
    } else {
      currentUser.cognitoUser = null;
      currentUser.attributes = {};
      currentUser.groups = [];
    }

    await notify('initialized');
  } catch (e) {
    await notifyError(e);
  }
};

export const login = async (username: string, password: string): Promise<void> => {
  await notify('loading');

  currentUser.cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool
  });

  await new Promise<void>((resolve, reject) => {
    if (currentUser.cognitoUser) {
      currentUser.cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: username,
          Password: password
        }),
        {
          async onFailure(error) {
            currentUser.cognitoUser = null;
            await notifyError(error);
            reject(error);
          },
          async onSuccess() {
            await initializeAuth();
            await notify('logged in', `You have successfully logged in as ${currentUser.attributes.name}`);
            resolve();
          }
        }
      );
    }
  });
};

export const logout = async (): Promise<void> => {
  await notify('loading');

  await new Promise<void>((resolve) => {
    if (currentUser.cognitoUser) {
      currentUser.cognitoUser.signOut(async () => {
        await notify('logged out', 'You have logged out.');
        await initializeAuth();
        resolve();
      });
    }
  });
};

export const signUp = async (name: string, username: string, password: string): Promise<void> => {
  await notify('loading');

  const attributes = [
    ...(name ? [new CognitoUserAttribute({
      Name: 'name',
      Value: name
    })] : []),
    ...(username ? [new CognitoUserAttribute({
      Name: 'email',
      Value: username
    })] : [])
  ];

  await new Promise<void>((resolve, reject) =>
    userPool.signUp(
      username,
      password,
      attributes,
      attributes,
      async (error) => {
        if (error) {
          await notifyError(error);
          reject(error);
        } else {
          await notify(
            'sign up progress',
            `Account created for ${name} <${username}>, next you need to verify your email address.`
          );
          resolve();
        }
      }
    ));
};

export const confirmSignup = async (username: string, confirmationCode: string): Promise<void> => {
  await notify('loading');

  await new Promise<void>((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool
    });

    cognitoUser.confirmRegistration(
      confirmationCode,
      true,
      async (error) => {
        if (error) {
          await notifyError(error);
          reject(error);
        } else {
          await notify('sign up progress', `Account confirmation complete for ${username}, you can now login.`);
          resolve();
        }
      }
    );
  });
};

export const recoverPassword = async (username: string): Promise<void> => {
  await notify('loading');

  await new Promise<void>((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool
    });

    cognitoUser.forgotPassword({
      async inputVerificationCode() {
        await notify('logged in', 'A recovery code has been sent to your email.');
        resolve();
      },
      async onSuccess() {
        // Ignore.
      },
      async onFailure(error) {
        await notifyError(error);
        reject(error);
      }
    });
  });
};

export const confirmRecoverPassword = async (
  username: string,
  recoveryCode: string,
  newPassword: string
): Promise<void> => {
  await notify('loading');

  await new Promise<void>((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool
    });

    cognitoUser.confirmPassword(
      recoveryCode,
      newPassword,
      {
        async onSuccess() {
          await notify('logged in', 'Your password has been reset, you can now login.');
          resolve();
        },
        async onFailure(error) {
          await notifyError(error);
          reject(error);
        }
      }
    );
  });
};

/* eslint-disable max-len */

import { Label, Path } from '@/api-schema';

export interface EntityType {
  path: Path;
  label: Label;
  icon: string;
  description: string;
}

const entityTypes: EntityType[] = [
  {
    path: '/video',
    label: 'Video',
    icon: 'mdi-video-outline',
    description: 'Videos of New Music in Western Australia, including performances, events and interviews.'
  },
  {
    path: '/audio',
    label: 'Audio',
    icon: 'mdi-headphones',
    description: 'Audio recordings, including studio and live performance recordings, relevant to New Music in Western Australia.'
  },
  {
    path: '/photographs',
    label: 'Photographs',
    icon: 'mdi-camera-outline',
    description: 'Photographs of Western Australian New Music events and performances, and the people and ensembles that make it happen.'
  },
  {
    path: '/documents',
    label: 'Documents',
    icon: 'mdi-book-outline',
    description: 'Documents about Western Australian New Music including posters, promotional material, sheet music, etc.'
  },
  {
    path: '/people',
    label: 'People',
    icon: 'mdi-account-circle-outline',
    description: 'People who are important to New Music in Western Australia.'
  },
  {
    path: '/ensembles',
    label: 'Ensembles',
    icon: 'mdi-account-group-outline',
    description: 'Ensembles who are significant to New Music in Western Australia.'
  },
  {
    path: '/organisations',
    label: 'Organisations',
    icon: 'mdi-domain',
    description: 'Organisations that make New Music possible in Western Australia.'
  },
  {
    path: '/locations',
    label: 'Locations',
    icon: 'mdi-map-outline',
    description: 'Locations that are important to the Western Australian New Music scene.'
  },
  {
    path: '/events',
    label: 'Events',
    icon: 'mdi-calendar-outline',
    description: 'Events that have been significant for New Music in Western Australia.'
  }
];

export const getEntityTypes = (): EntityType[] => entityTypes;

export const getEntityType = (type: string): EntityType | undefined =>
  entityTypes.find(({ path }) => path === `/${type}`);






























import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import { SearchResponse } from 'instantsearch.js';
import { Entity } from '@/api-schema';
import { getSearchIndex } from '@/services/algolia';
import EntityCarousel from '@/components/home/EntityCarousel.vue';
import EntityTypesList from '@/components/home/EntityTypesList.vue';
import WhatIsNewMusic from '@/components/home/WhatIsNewMusic.vue';
import WhatIsWanma from '@/components/home/WhatIsWanma.vue';
import { None } from '@/util/types';

interface Data {
  featuredEntities: Entity[];
}

interface Computed {
  showFeatured: boolean;
}

export default Vue.extend<Data, None, Computed>({
  name: 'Home',
  data() {
    return {
      featuredEntities: [] as Entity[]
    };
  },
  computed: {
    showFeatured() {
      return this.$data.featuredEntities.length > 0;
    }
  },
  components: {
    WhatIsWanma,
    WhatIsNewMusic,
    EntityTypesList,
    EntityCarousel
  },
  async beforeMount() {
    const result = await getSearchIndex().search<Entity>('', {
      facetFilters: ['tags:feature'],
      hitsPerPage: 48
    }) as SearchResponse<Entity>;
    const randomisedHits = [...result.hits];
    for (let i = 0; i < 10; i += 1) {
      randomisedHits.sort(() => Math.random() - 0.5);
    }
    this.featuredEntities = randomisedHits.slice(0, result.hits.length - (result.hits.length % 6));
  },
  metaInfo(): MetaInfo {
    return {
      title: '',
      titleTemplate: 'Western Australian New Music Archive',
      meta: [
        {
          name: 'og:title',
          content: 'Western Australian New Music Archive'
        },
        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:image',
          content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
        },
        {
          name: 'og:url',
          content: window.location.href
        },
        {
          name: 'og:author',
          content: 'WANMA'
        },
        {
          name: 'og:description',
          content: 'The Western Australian New Music Archive is an interactive web portal presenting a collection of'
            + 'content related to the history of new music in Western Australia. You will find a range of material'
            + 'here including audio and video recordings, images, and a range of documents.'
        }
      ]
    };
  }
});

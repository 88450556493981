import { gql } from '@apollo/client/core';
import { ApolloProvider } from 'vue-apollo';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Entity, GetEntityByPathInput } from '@/api-schema';
import { articleFields, commonFields } from '@/services/api/fields';
import { deepCopy } from '@/util/deep';

const retrieveArticleEntityQuery = gql`
  query RetrieveArticleEntity($input: GetEntityByPathInput!) {
    entity: getEntityByPath(input: $input) {
      ${commonFields}
      ${articleFields}
    }
  }
`;

export const retrieveArticleEntity = async (
  apolloProvider: ApolloProvider,
  input: GetEntityByPathInput,
  user: CognitoUser | null
): Promise<Entity> => {
  const apolloClient = user ? apolloProvider.clients.authenticated : apolloProvider.clients.unauthenticated;
  const result = await apolloClient.query({
    query: retrieveArticleEntityQuery,
    variables: { input }
  });
  return deepCopy(result.data.entity);
};

import { UiState } from 'instantsearch.js';
import VueRouter, { Location, Route } from 'vue-router';

type Query = Record<string, string | (string | null)[] | null | undefined>;

type RouteState = Partial<Record<string, {
  q: string | undefined;
  type: string[] | undefined;
  tags: string[] | undefined;
}>>;

// Copied from `router.d.ts` (vue-router package) where it is given inline, not as a named type.
interface ResolvedRoute {
  location: Location;
  route: Route;
  href: string;
  normalizedTo: Location;
  resolved: Route;
}

export interface SearchRoutingProperties {
  router: {
    onPopState?: (event: PopStateEvent) => void;
    removeAfterEach?: () => void;
    read(): Record<string, string | (string | null)[]>;
    write(query: Query): void;
    createURL(query: Query): ResolvedRoute;
    onUpdate(callback: (result: Record<string, string | (string | null)[]>) => void): void;
    dispose(): void;
  },
  stateMapping: {
    stateToRoute(uiState: UiState): RouteState;
    routeToState(routeState: RouteState): UiState;
  }
}

export const createSearchRoutingProperties = (vueRouter: VueRouter, indexName: string): SearchRoutingProperties => ({
  router: {
    onPopState: undefined,
    removeAfterEach: undefined,
    read() {
      return vueRouter.currentRoute.query;
    },
    write(query) {
      if (this.onPopState) {
        vueRouter.push({ query }, (e: Event) => {
          if (typeof e.preventDefault === 'function') {
            e.preventDefault();
          }
        });
      }
    },
    createURL(query) {
      return vueRouter.resolve({ query });
    },
    onUpdate(callback) {
      this.removeAfterEach = vueRouter.afterEach(() => {
        callback(this.read());
      }) as () => void;
      this.onPopState = (event: PopStateEvent) => {
        callback(event.state || this.read());
      };
      window.addEventListener('popstate', this.onPopState);
    },
    dispose() {
      if (this.onPopState) {
        window.removeEventListener('popstate', this.onPopState);
        this.onPopState = undefined;
      }
      if (this.removeAfterEach) {
        this.removeAfterEach();
        this.removeAfterEach = undefined;
      }
    }
  },
  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState[indexName];
      return {
        q: indexUiState.query,
        type: indexUiState.refinementList?.type,
        tags: indexUiState.refinementList?.tags
      } as unknown as RouteState;
    },
    routeToState(routeState) {
      return {
        [indexName]: {
          query: routeState.q,
          refinementList: {
            type: routeState.type,
            tags: routeState.tags
          }
        }
      } as unknown as UiState;
    }
  }
});

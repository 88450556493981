



















































import Vue from 'vue';
import { Link, LinkType, URL } from '@/api-schema';
import bandcamp from '@/assets/images/social/bandcamp.png';
import facebook from '@/assets/images/social/facebook.png';
import instagram from '@/assets/images/social/instagram.png';
import linkedin from '@/assets/images/social/linkedin.png';
import reddit from '@/assets/images/social/reddit.png';
import snapchat from '@/assets/images/social/snapchat.png';
import soundcloud from '@/assets/images/social/soundcloud.png';
import tiktok from '@/assets/images/social/tiktok.png';
import twitch from '@/assets/images/social/twitch.png';
import twitter from '@/assets/images/social/twitter.png';
import vimeo from '@/assets/images/social/vimeo.png';
import website from '@/assets/images/social/website.png';
import youtube from '@/assets/images/social/youtube.png';

interface Props {
  value?: Link[];
  editable: boolean;
  activity: boolean;
  heading: string;
}

interface Data {
  adding: boolean;
  addingType: LinkType;
  addingUrl: URL;
}

interface Methods {
  toggleAdding(): void;
  getLinkIndex(linkToFind: Link): number;
  addLink(): void;
  removeLink(evt: Event, link: Link): void;
}

interface Computed {
  shouldDisplay: boolean;
  icons: Record<LinkType, unknown>;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'WebAndSocialsCard',
  props: {
    value: {
      type: Array as () => Link[],
      required: false
    },
    editable: {
      type: Boolean,
      required: true
    },
    activity: {
      type: Boolean,
      required: true
    },
    heading: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      adding: false,
      addingType: 'Website' as LinkType,
      addingUrl: ''
    };
  },
  methods: {
    toggleAdding() {
      this.adding = !this.adding;
    },
    getLinkIndex(linkToFind) {
      return (this.value || [])
        .map(({ type, url }) => `${type}::${url}`)
        .indexOf(`${linkToFind.type}::${linkToFind.url}`);
    },
    addLink() {
      const linkToAdd = { type: this.addingType, url: this.addingUrl } as Link;
      if (!this.value || !this.addingUrl || this.getLinkIndex(linkToAdd) >= 0) {
        return;
      }
      this.value.push(linkToAdd);
      this.$emit('input', this.value);
      this.addingType = 'Website';
      this.addingUrl = '';
    },
    removeLink(evt, linkToRemove) {
      evt.stopPropagation();
      evt.preventDefault();
      if (!this.value) {
        return;
      }
      const index = this.getLinkIndex(linkToRemove);
      if (index >= 0) {
        this.value.splice(index, 1);
        this.$emit('input', this.value);
      }
      this.addingType = 'Website';
      this.addingUrl = '';
    }
  },
  computed: {
    shouldDisplay() {
      return this.activity || this.editable || (!!this.value && this.value.length > 0);
    },
    icons() {
      return {
        BandCamp: bandcamp,
        Facebook: facebook,
        Instagram: instagram,
        LinkedIn: linkedin,
        Reddit: reddit,
        SnapChat: snapchat,
        SoundCloud: soundcloud,
        TikTok: tiktok,
        Twitch: twitch,
        Twitter: twitter,
        Vimeo: vimeo,
        Website: website,
        YouTube: youtube
      };
    }
  }
});

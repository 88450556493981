















import Vue from 'vue';
import { createWidgetMixin } from 'vue-instantsearch';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { SearchBoxRenderState } from 'instantsearch.js/es/connectors/search-box/connectSearchBox';
import { None } from '@/util/types';

interface Data {
  timerId?: number;
  localQuery: string;
  query: string;
}

interface Computed {
  query: string;
}

interface Props {
  value: string;
}

export default Vue.extend<Data, None, Computed, Props>({
  name: 'SearchBox',
  mixins: [
    createWidgetMixin({ connector: connectSearchBox })
  ],
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      timerId: undefined as undefined | number,
      localQuery: this.value,
      query: this.value
    };
  },
  destroyed() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },
  watch: {
    query(value) {
      this.localQuery = value;
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = window.setTimeout(() => {
        (this as unknown as { state: SearchBoxRenderState }).state.refine(this.localQuery);
      }, 600);
    }
  }
});

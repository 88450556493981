export const commonFields = `
      path
      redirect
      label
      slug
      type
      body
      tags
      published
      lastModified {
        by
        at
      }
      avatarUrl
      related {
        path
        label
        type
        body
        published
        avatarUrl
      }
`;

export const articleFields = `
      birthDeathDates
      occurrences
      references
      links {
        type
        url
      }
      selectedRecordings
      selectedWorks
      yearsActive
`;

export const mediaFields = `
      provenance {
        origin
        url
      }
      mediaSources {
        mimeType
        url
        description
      }
`;

export const stripMetadata = <T extends Record<string, unknown> = Record<string, unknown>>(data: T): Partial<T> =>
  Object.keys(data)
    .filter((key) => !key.startsWith('_'))
    .reduce<Partial<T>>(
      (result, key) => ({
        ...result,
        [key]: (data[key] && typeof data[key] === 'object')
          ? stripMetadata(data[key] as Record<string, unknown>)
          : data[key]
      }),
      {}
    );



















import Vue from 'vue';
import { None } from '@/util/types';
import Attribute from '@/components/entity/Attribute.vue';

interface Data {
  currentValue?: string;
}

interface Computed {
  shouldDisplay: boolean;
}

interface Props {
  value?: string;
  editable: boolean;
  activity: boolean;
  heading: string;
  placeholder?: string;
}

export default Vue.extend<Data, None, Computed, Props>({
  name: 'AttributeCard',
  props: {
    value: {
      type: String,
      required: false
    },
    editable: {
      type: Boolean,
      required: true
    },
    activity: {
      type: Boolean,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      currentValue: this.value
    };
  },
  computed: {
    shouldDisplay() {
      return this.editable || !!this.currentValue;
    }
  },
  watch: {
    value() {
      this.currentValue = this.value || '';
    }
  },
  components: { Attribute }
});





























































































import Vue from 'vue';
import { EntityType, getEntityTypes } from '@/util/entityTypes';

interface Data {
  showDrawer: boolean;
}

interface Methods {
  logout(): Promise<void>;
}

interface Computed {
  loginPath: string;
  signupPath: string;
  profilePath: string;
  entityTypes: EntityType[];
}

export default Vue.extend<Data, Methods, Computed>({
  name: 'AppBar',
  data() {
    return {
      showDrawer: false
    };
  },
  methods: {
    async logout() {
      await this.$auth.logout();
    }
  },
  computed: {
    loginPath() {
      return this.$auth.getLoginPath(this.$route);
    },
    signupPath() {
      return this.$auth.getSignupPath(this.$route);
    },
    profilePath() {
      return this.$auth.getProfilePath();
    },
    entityTypes() {
      return getEntityTypes();
    }
  }
});

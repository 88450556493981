

















































import Vue from 'vue';
import { MetaInfo } from 'vue-meta';

interface Data {
  username: string;
  password: string;
}

interface Methods {
  login(evt: Event): Promise<void>;
  redirectIfLoggedIn(): void;
}

interface Computed {
  signupPath: string;
  recoverPasswordPath: string;
}

export default Vue.extend<Data, Methods, Computed>({
  name: 'Login',
  data() {
    return {
      username: `${this.$route.query.email || ''}`,
      password: ''
    };
  },
  methods: {
    async login(evt: Event): Promise<void> {
      evt.preventDefault();
      try {
        await this.$auth.login(this.username, this.password);
        await this.$router.push(this.$auth.getRouteReturnPath(this.$route));
      } catch (e) {
        // Ignore error, it is displayed as a Snackbar, see AuthNotifications component
      }
    },
    redirectIfLoggedIn() {
      if (this.$user.cognitoUser) {
        this.$router.push(this.$auth.getRouteReturnPath(this.$router.currentRoute));
      }
    }
  },
  computed: {
    signupPath() {
      return this.$auth.getSignupPath(this.$route, this.$data.username);
    },
    recoverPasswordPath() {
      return this.$auth.getRecoverPasswordPath(this.$route, this.$data.username);
    }
  },
  beforeMount() {
    this.redirectIfLoggedIn();
  },
  authEvent() {
    this.redirectIfLoggedIn();
  },
  metaInfo(): MetaInfo {
    return {
      title: 'Log in to WANMA',
      meta: [
        {
          name: 'og:title',
          content: 'Log in to WANMA'
        },
        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:image',
          content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
        },
        {
          name: 'og:url',
          content: window.location.href
        },
        {
          name: 'og:author',
          content: 'WANMA'
        },
        {
          name: 'og:description',
          content: 'The Western Australian New Music Archive (WANMA) is an Australian Research Council supported '
            + 'Linkage project that aims to develop and establish a digital repository and interface of new music by '
            + 'Western Australian composers from 1970 to the current day.'
        }
      ]
    };
  }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('PassiveMessage',{attrs:{"error":""},model:{value:(_vm.errorMessage),callback:function ($$v) {_vm.errorMessage=$$v},expression:"errorMessage"}}),_c('v-card',[_c('v-card-title',{staticClass:"text-h5 font-weight-light"},[_c('v-container',[_c('v-row',[_vm._v(" Import requests "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.loadData}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.importRequests,"item-key":"id","loading":_vm.activity},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBNumber(item.name))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startDate))+" ")]}},{key:"item.stopDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.stopDate))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatSeconds((item.stopDate || Date.now()) - item.startDate))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
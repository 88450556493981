















































import Vue from 'vue';
import { MetaInfo } from 'vue-meta';

interface Data {
  username: string;
}

interface Methods {
  recoverPassword(evt: Event): Promise<void>;
  redirectIfLoggedIn(): void;
}

interface Computed {
  canSubmit: boolean;
  signupPath: string;
  loginPath: string;
}

export default Vue.extend<Data, Methods, Computed>({
  name: 'RecoverPassword',
  data() {
    return {
      username: `${this.$route.query.email || ''}`
    };
  },
  methods: {
    async recoverPassword(evt: Event): Promise<void> {
      evt.preventDefault();
      if (!this.username) {
        return;
      }
      try {
        await this.$auth.recoverPassword(this.username);
        await this.$router.push(this.$auth.getRecoverPasswordConfirmationPath(this.$route, this.username));
      } catch (e) {
        // Ignore error, it is displayed as a Snackbar, see AuthNotifications component
      }
    },
    redirectIfLoggedIn() {
      if (this.$user.cognitoUser) {
        this.$router.push(this.$auth.getRouteReturnPath(this.$router.currentRoute));
      }
    }
  },
  computed: {
    canSubmit() {
      return !!this.$data.username;
    },
    signupPath() {
      return this.$auth.getSignupPath(this.$route, this.$data.username);
    },
    loginPath() {
      return this.$auth.getLoginPath(this.$route, this.$data.username);
    }
  },
  beforeMount() {
    this.redirectIfLoggedIn();
  },
  authEvent() {
    this.redirectIfLoggedIn();
  },
  metaInfo(): MetaInfo {
    return {
      title: 'Recover your WANMA password',
      meta: [
        {
          name: 'og:title',
          content: 'Recover your WANMA password'
        },
        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:image',
          content: `${window.location.protocol}//${window.location.host}/images/logo/wanma-logo-90.png`
        },
        {
          name: 'og:url',
          content: window.location.href
        },
        {
          name: 'og:author',
          content: 'WANMA'
        },
        {
          name: 'og:description',
          content: 'The Western Australian New Music Archive (WANMA) is an Australian Research Council supported '
            + 'Linkage project that aims to develop and establish a digital repository and interface of new music by '
            + 'Western Australian composers from 1970 to the current day.'
        }
      ]
    };
  }
});
